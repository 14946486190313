import {
  IArticleData,
  IAuthorBoxData,
  IAuthorWithArticleProps
} from '../models/author-with-article-model';

export interface IAuthorData {
  authorData: IAuthorBoxData;
  articles: IArticleData[];
}

export const authorsDataResolver = (
  data: IAuthorWithArticleProps[]
): IAuthorData[] => {
  const author = data.reduce((results, current) => {
    if (current.author.slug === 'tenderhutdigital') {
      return false;
    }

    const articles = data
      .filter(obj => obj.author.id === current.author.id)
      .map(obj => obj.article);

    return {
      ...results,
      [current.author.id]: {
        authorData: current.author,
        articles: articles
      }
    };
  }, {});

  return Object.values(author);
};
